@use '@skyscanner/backpack-web/unstable__bpk-mixins/breakpoints';
@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';
@use '../../mixins';
@use 'utils';

.Container {
  @include mixins.custom-pages-grid;

  &__HandleFirstSection {
    .Item {
      display: block;

      @include breakpoints.bpk-breakpoint-mobile {
        @include utils.hide-from(4);
      }

      @include breakpoints.bpk-breakpoint-small-tablet-only {
        @include utils.hide-from(5);
      }

      @include utils.breakpoint-small-tablet-to-desktop {
        @include utils.hide-from(7);
      }

      @include breakpoints.bpk-breakpoint-above-desktop {
        @include utils.hide-from(9);
      }
    }
  }

  .Grid {
    position: relative;
    display: grid;
    grid-template-rows: auto;
    align-items: start;
    gap: tokens.bpk-spacing-xl() tokens.bpk-spacing-lg();

    // Columns
    @include breakpoints.bpk-breakpoint-mobile {
      grid-template-columns: repeat(1, 1fr);
    }

    @include breakpoints.bpk-breakpoint-small-tablet-only {
      grid-template-columns: repeat(2, 1fr);
    }

    @include utils.breakpoint-small-tablet-to-desktop {
      grid-template-columns: repeat(3, 1fr);
    }

    @include breakpoints.bpk-breakpoint-above-desktop {
      grid-template-columns: repeat(4, 1fr);
    }

    .Item {
      position: relative;

      // Masonry Layout
      @include breakpoints.bpk-breakpoint-mobile {
        $per-section: 3;

        @include utils.short-images($per-section, 1 2 3);
      }

      @include breakpoints.bpk-breakpoint-small-tablet-only {
        $per-section: 4;

        @include utils.tall-images($per-section, 1 4);
        @include utils.short-images($per-section, 2 3);
        @include utils.shift-up($per-section, 4);
        @include utils.pad-bottom($per-section, 2);
      }

      @include utils.breakpoint-small-tablet-to-desktop {
        $per-section: 6;

        @include utils.tall-images($per-section, 1 3 5);
        @include utils.short-images($per-section, 2 4 6);
        @include utils.shift-up($per-section, 5);
        @include utils.pad-bottom($per-section, 2);
      }

      @include breakpoints.bpk-breakpoint-above-desktop {
        $per-section: 8;

        @include utils.tall-images($per-section, 1 3 6 8);
        @include utils.short-images($per-section, 2 4 5 7);
        @include utils.shift-up($per-section, 6 8);
        @include utils.pad-bottom($per-section, 2 4);
      }

      &__Hidden {
        display: none;
      }

      &__Inserted {
        animation: appear tokens.$bpk-duration-sm ease-in-out;
        transform-origin: center;
      }
    }
  }

  .ButtonContainer {
    width: 100%;
    padding: tokens.bpk-spacing-xl() 0 0;
    text-align: center;

    &__Hidden {
      display: none;
    }
  }
}
