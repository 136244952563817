@use '@skyscanner/backpack-web/unstable__bpk-mixins/tokens';

$image-height-diff: 38%;

@keyframes appear {
  0% {
    top: 15 * tokens.$bpk-one-pixel-rem;
    transform: scale(0.5);
    opacity: 0.01;
  }

  40% {
    opacity: 1;
  }

  100% {
    top: 0;
    transform: scale(1);
  }
}

@mixin tall-images($perSection, $indexes) {
  @each $index in $indexes {
    &:nth-child(#{$perSection}n + #{$index}) {
      .ItemShort {
        display: none;
      }
    }
  }
}

@mixin short-images($perSection, $indexes) {
  @each $index in $indexes {
    &:nth-child(#{$perSection}n + #{$index}) {
      .ItemTall {
        display: none;
      }
    }
  }
}

@mixin shift-up($perSection, $indexes) {
  @each $index in $indexes {
    &:nth-child(#{$perSection}n + #{$index}) {
      margin-top: -$image-height-diff;
    }
  }
}

@mixin pad-bottom($perSection, $indexes) {
  @each $index in $indexes {
    &:nth-child(#{$perSection}n + #{$index}) {
      margin-bottom: $image-height-diff;
    }
  }
}

@mixin hide-from($index) {
  &:nth-child(n + #{$index}) {
    display: none;
  }
}

$breakpoint-query-small-tablet-to-desktop: '(min-width: #{tokens.$bpk-breakpoint-small-tablet}) and (max-width: #{tokens.$bpk-breakpoint-desktop})';

@mixin breakpoint-small-tablet-to-desktop {
  @media #{$breakpoint-query-small-tablet-to-desktop} {
    @content;
  }
}
